import { Component, useState } from "react";
import Work from "./Work_box";
import "./style.css";
import { Element } from "react-scroll";

// Desktop/Laptop PCs, components, Servers, Storage, Security, Networking, solutions, Attendance  Face and Biometric Devices, Hospitality TVs and Commercial Display/ Signages etc.
const Portfolio = () => {
  const works = [
    {
      img: "/assets/supreme/desktops_and_laptops.png",
      title: "Desktop/Laptops",
      text:
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
          "We provide a wide range of Desktops and Laptops from leading brands like HP, Dell, Lenovo, Acer, Asus, MSI, Gigabyte, Apple, Microsoft, etc. We also provide custom built desktops and laptops as per the customer requirements."
      },
    {
      img:
        "/assets/supreme/server1.jpg",
      title: "Servers",
      text:
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
        "We provide a wide range of Servers from leading brands like HP, Dell, Lenovo, IBM, Supermicro, etc. We also provide custom built servers as per the customer requirements."
      },
    {
      img:
        "/assets/supreme/components.jpg",
      title: "Components",
      text:
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
        "We provide a wide range of components like Motherboards, Processors, RAM, Hard Disks, SSDs, Graphics Cards, Power Supplies, Cabinets, Monitors, Keyboards, Mouse, etc. from leading brands like Intel, AMD, Kingston, Seagate, WD, Samsung, LG, Asus, MSI, Gigabyte, Cooler Master, Corsair, etc."
      },
      {
        img:"/assets/supreme/thin_client.png",
        title : "Thin Clients",
        text : "We provide a wide range of Thin Clients from leading brands like HP, Dell, Lenovo, NComputing, etc. We also provide custom solutions as per the customer requirements."
      },
      {
        img:"/assets/supreme/zero_client.png",
        title : "Zero Clients",
        text : "We provide a wide range of Zero Clients from leading brands like HP, Dell, Lenovo, NComputing, etc. We also provide custom solutions as per the customer requirements."
      },
    {
      img:
        "/assets/supreme/fingerprint.jpg",
      title: "Attendance  Face and Biometric Devices",
      text:
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
        "We provide a wide range of Attendance, Face and Biometric Devices from leading brands like ESSL, ZKTeco, Realtime, etc. We also provide custom solutions as per the customer requirements."
      },
    {
      img:
        "/assets/supreme/storage.jpg",
      title: "Storage",
      text:
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
        "We provide a wide range of Storage Solutions like NAS, SAN, DAS, Cloud Storage, etc. from leading brands like Synology, Qnap, Seagate, WD, Samsung, etc. We also provide custom solutions as per the customer requirements."
      },
    {
      img:
        "/assets/supreme/security.jpg",
      title: "Security",
      text:
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
        "We provide a wide range of Security Solutions like Antivirus, Firewall, VPN, UTM, etc. from leading brands like Kaspersky, Bitdefender, Symantec, Fortinet, Sonicwall, Sophos, etc. We also provide custom solutions as per the customer requirements."
      },
      {
        img:"/assets/supreme/wifi.png",
        title : "Campuswide Wifi Solutions",
        text : "We provide a wide range of Wifi Solutions like Access Points, Routers, Switches, etc. from leading brands like D-Link, TP-Link, Netgear, Linksys, Digisol, etc. We also provide custom solutions as per the customer requirements."

      },
    {
      img:
        "/assets/supreme/networking.png",
      title: "Networking Solutions",
      text:
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
        "We provide a wide range of Networking Solutions like Routers, Switches, Access Points, Modems, Cables, Connectors, etc. from leading brands like D-Link, TP-Link, Netgear, Linksys, Digisol, etc. We also provide custom solutions as per the customer requirements."
      },
      {
        img:"/assets/supreme/workstations.png",
        title : "Workstations",
        text : "We provide a wide range of GPU Workstations from leading brands like HP, Dell, Lenovo, etc. We also provide custom solutions as per the customer requirements."
      },{
        img:"/assets/supreme/print.png",
        title : "Enterprise class print solutions",
        text : "We provide a wide range of Print Solutions like Printers, Scanners, Copiers, etc. from leading brands like HP, Canon, Epson, Samsung, Xerox, etc. We also provide custom solutions as per the customer requirements."
      },
      {
        img:"/assets/supreme/displays.png",
        title : "Professional Large Format Displays, Interactive Display boards, Gaming monitors",
        text : "We provide a wide range of Displays like Monitors, Projectors, Interactive Display boards, etc. from leading brands like LG, Samsung, Dell, BenQ, ViewSonic, etc. We also provide custom solutions as per the customer requirements."
      },
      {
        img:"/assets/supreme/conference.png",
        title : "Video Conferencing solutions and Professional headsets",
        text : "We provide a wide range of Video Conferencing Solutions like Webcams, Conference Cameras, Speakerphones, Headsets, etc. from leading brands like Logitech, Polycom, Jabra, Plantronics, etc. We also provide custom solutions as per the customer requirements."
      }
      
  ];

  const[loadMore, setLoadMore] = useState(false);
  const[loadMoreText, setLoadMoreText] = useState("Load More");
  const loadMoreThreshold = 2;
  const toggleLoadMore = () => {
    setLoadMore(!loadMore);
    setLoadMoreText(loadMore ? "Load More" : "Load Less");
  }

  return (
    <Element className="container container__portfolio" name="portfolio">
      {/* <div className="portfolio__header">
        <h1>See Our Works Or Portfolio</h1>
        <button className="btn btn-primary">More About</button>
      </div> */}
      {works.map((work, index) => {
        if (index == 1) {
          return (
            <div key={index}>
              <div className="portfolio__header" >
                <h1>Explore Our Products and Services</h1>
              </div>
              <div className="portfolio_button_container">
                <button className="btn btn-primary" onClick={toggleLoadMore}>{loadMoreText}</button>
              </div>
                <br />
              <Work img={work.img} title={work.title} text={work.text}  />
            </div>
          );
        }
        if (!loadMore && index >= loadMoreThreshold) {
          return null;
        }
        return <div key={index}>
                <Work img={work.img} title={work.title} text={work.text} />
              </div>
      })}
    </Element>
  );
}
/*
class Portfolio extends Component {
  constructor() {
    super();
    this.works = [
      {
        img: "https://miro.medium.com/max/1400/0*MYd28f8vpLiE34ij.jpeg",
        title: "Modern UI/UX Dashboard Design",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      },
      {
        img:
          "https://i.pinimg.com/originals/80/a1/17/80a1173fb50fe01bac507532338eb336.png",
        title: "Food and Consumation Web App",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      },
      {
        img:
          "https://i.pinimg.com/originals/56/ae/76/56ae76f7bc1b3e0edc962cea1af7035f.png",
        title: "Future Social Media Web 3.0 d'App",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      }
    ];
  }
  render() {
    return (
      <section className="container container__portfolio" id="portfolio">
        // {<div className="portfolio__header">
        //   <h1>See Our Works Or Portfolio</h1>
        //   <button className="btn btn-primary">More About</button>
        // </div> }
        {this.works.map((work, index) => {
          if (index == 1) {
            return (
              <>
                <div className="portfolio__header">
                  <h1>See Our Works Or Portfolio</h1>
                  <button className="btn btn-primary">More About</button>
                </div>
                <Work img={work.img} title={work.title} text={work.text} />
              </>
            );
          }
          return <Work img={work.img} title={work.title} text={work.text} />;
        })}
      </section>
    );
  }
}
*/
export default Portfolio;
