import Button from "../Global__Components/Button";
import { Link } from "react-scroll";

const Quote = () => {

  const buttonContent = (
    <Link to="quote" spy={true} smooth={true} offset={-200} duration={100}>
      Get a Quote
    </Link>
  );
  return (
    <div className="quote-button">
      <Button content={buttonContent}  />
    </div>
  );
};
/*
class Quote extends Component {
  render() {
    return <Button content="Get a Quote" />;
  }
}
*/
export default Quote;
