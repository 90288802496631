import { Link } from "react-scroll";
import "./style.css";
const Logo = () => {
  return (
    <div className="logo">
      {/* <h3>TRITA MEDS</h3> */}
      {/* put a jpg in the local public folder*/}
      <Link to="about" spy={true} smooth={true} offset={-200} duration={100}>
        <img className="logo_icon" src="/assets/logo.png" alt="logo" />
      </Link>
    </div>
  );
};
export default Logo;
/*
class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <h3>LOGO</h3>
      </div>
    );
  }
}
export default Logo;
*/
