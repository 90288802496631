import { Component } from "react";

const Button = (props) => {
  return (
    <button className="btn" onClick={props.click}>
      {props.content}
    </button>
  );
};
/*
class Button extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <button className="btn" onClick={this.props.click}>
        {this.props.content}
      </button>
    );
  }
}
*/
export default Button;
