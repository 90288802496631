import React, { useState } from "react";
import axios from "axios";
import "./styles.css"; // Import CSS file for styling
import toast from "react-hot-toast";
import { Element } from "react-scroll";

const ContactForm = () => {
  // State variables to store form input values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    toast.success("Form submitted successfully!");
    // Here you can perform actions like sending the form data to a server
    console.log("Form submitted:", { name, email, message });

    // const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    // const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    // const public_key = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Admin",
      message: message,
      phone: phone,
      company: company,
    };

    const data = {
      service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      user_id: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      template_params: templateParams,
    };

    // POST https://api.emailjs.com/api/v1.0/email/send
    // use axios
    axios.post(`https://api.emailjs.com/api/v1.0/email/send`, data);

    // Reset form fields
    setName("");
    setEmail("");
    setMessage("");
    setPhone("");
    setCompany("");
  };

  return (
    <Element name="quote">
      <div className="contact-form-container">
        <h2>Get a Quote </h2>
        <br/>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="number"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="company">Organization:</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </Element>
  );
};

export default ContactForm;
