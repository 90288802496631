import { Component, useState } from "react";
import { GrShieldSecurity } from "react-icons/gr";
import { BsDisc, BsFingerprint, BsLaptop, BsPower, BsServer } from "react-icons/bs";
import Button from "../Global__Components/Button";
import { BiSolidNetworkChart, BiStation, BiVideo } from "react-icons/bi";
import { Element } from "react-scroll";
import { PiComputerTower, PiPrinter } from "react-icons/pi";
import { FaServer } from "react-icons/fa";
import { TiWiFi } from "react-icons/ti";
import { FiMonitor } from "react-icons/fi";

const ServicesComp = () => {
  
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreText, setLoadMoreText] = useState("Load More");
  const toggleLoadMore = () => {
    setLoadMore(!loadMore);
    setLoadMoreText(loadMore ? "Load More" : "Load Less");
  }
  const loadLessThreshold = 4;
  const ServicesItem = (props) => {
    return (
      <div>
        <div className="icon">
          {props.icon}
        </div>
        <h4>{props.title}</h4>
        <p>{props.content}</p>
      </div>
    );

  }

  // servives Item list 
  const servicesItems = [
    {
      icon: <BiStation />,
      title: "High Power Workstations",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BsPower />,
      title: "UPS Systems and Power Solutions",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BiVideo />,
      title: "GPU Workstations",
      content: "Lorem ipsum dolor sit amet consectetur."
    },

    {
      icon: <BsLaptop />,
      title: "Personal Computers",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <PiComputerTower />,
      title: "Thin Clients",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <FaServer />,
      title: "Zero Clients",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BsFingerprint />,
      title: "Biometric Systems",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BsDisc />,
      title: "Storage and Security",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BiSolidNetworkChart />,
      title: "Networking Solutions",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BsServer />,
      title: "Servers",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BsFingerprint />,
      title: "Biometric Systems",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BsDisc />,
      title: "Storage and Security",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <TiWiFi />,
      title: "Campus Wide Wifi Solutions",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <BiSolidNetworkChart />,
      title: "Networking Solutions",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon:<PiPrinter />,
      title: "Enterprise class and Managed Print Solutions",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
    {
      icon: <FiMonitor />,
      title: "Displays and Monitors",
      content: "Lorem ipsum dolor sit amet consectetur."
    },
  ];

  return (
    <Element name="products" className="services__servicesComp">
      <div>
        <h1>Our Products and Services That Empower Your Business</h1>
        <button className="btn btn-primary" onClick={toggleLoadMore}>{loadMoreText}</button>
      </div>
      <div>
        {servicesItems.map((item, index) => {
          if (!loadMore && index >= loadLessThreshold) {
            return null;
          }
          return (
            <ServicesItem
              key={index}
              icon={item.icon}
              title={item.title}
              // content={item.content}
            />
          );
        })}
      </div>
    </Element>
  );
}
/*
class ServicesComp extends Component {
  render() {
    return (
      <div className="services__servicesComp">
        <div>
          <h1>Our Servcices For Technology You Need</h1>
          <button className="btn btn-primary">Load More</button>
        </div>
        <div>
          <div>
            <div className="icon">
              <GrShieldSecurity />
            </div>
            <h4>Cyber Security</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <div className="icon">
              <BsLaptop />
            </div>
            <h4>IT Consultant</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <div className="icon">
              <GrShieldSecurity />
            </div>
            <h4>Cyber Security</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <div className="icon">
              <BsLaptop />
            </div>
            <h4>IT Consultant</h4>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
          </div>
        </div>
      </div>
    );
  }
}
*/
export default ServicesComp;
