import { Component, useState } from "react";
import ContactUsCard from "./ContactUsCard";
import "./style.css";

// Desktop/Laptop PCs, components, Servers, Storage, Security, Networking, solutions, Attendance  Face and Biometric Devices, Hospitality TVs and Commercial Display/ Signages etc.
const ContactInfoFooter = () => {
  // const works = [
  //   {
  //     img: "/assets/supreme/desktop_laptop.jpg",
  //     title: "Desktop/Laptops",
  //     text:
  //       // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
  //         "We provide a wide range of Desktops and Laptops from leading brands like HP, Dell, Lenovo, Acer, Asus, MSI, Gigabyte, Apple, Microsoft, etc. We also provide custom built desktops and laptops as per the customer requirements."
  //     },
  //   {
  //     img:
  //       "/assets/supreme/server1.jpg",
  //     title: "Servers",
  //     text:
  //       // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
  //       "We provide a wide range of Servers from leading brands like HP, Dell, Lenovo, IBM, Supermicro, etc. We also provide custom built servers as per the customer requirements."
  //     },
  //   {
  //     img:
  //       "/assets/supreme/components.jpg",
  //     title: "Components",
  //     text:
  //       // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
  //       "We provide a wide range of components like Motherboards, Processors, RAM, Hard Disks, SSDs, Graphics Cards, Power Supplies, Cabinets, Monitors, Keyboards, Mouse, etc. from leading brands like Intel, AMD, Kingston, Seagate, WD, Samsung, LG, Asus, MSI, Gigabyte, Cooler Master, Corsair, etc."
  //     },
  //   {
  //     img:
  //       "/assets/supreme/fingerprint.jpg",
  //     title: "Attendance  Face and Biometric Devices",
  //     text:
  //       // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
  //       "We provide a wide range of Attendance, Face and Biometric Devices from leading brands like ESSL, ZKTeco, Realtime, etc. We also provide custom solutions as per the customer requirements."
  //     },
  //   {
  //     img:
  //       "/assets/supreme/storage.jpg",
  //     title: "Storage",
  //     text:
  //       // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
  //       "We provide a wide range of Storage Solutions like NAS, SAN, DAS, Cloud Storage, etc. from leading brands like Synology, Qnap, Seagate, WD, Samsung, etc. We also provide custom solutions as per the customer requirements."
  //     },
  //   {
  //     img:
  //       "/assets/supreme/security.jpg",
  //     title: "Security",
  //     text:
  //       // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
  //       "We provide a wide range of Security Solutions like Antivirus, Firewall, VPN, UTM, etc. from leading brands like Kaspersky, Bitdefender, Symantec, Fortinet, Sonicwall, Sophos, etc. We also provide custom solutions as per the customer requirements."
  //     },
  //   {
  //     img:
  //       "/assets/supreme/networking.jpg",
  //     title: "Networking Solutions",
  //     text:
  //       // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
  //       "We provide a wide range of Networking Solutions like Routers, Switches, Access Points, Modems, Cables, Connectors, etc. from leading brands like Cisco, D-Link, TP-Link, Netgear, Linksys, Digisol, etc. We also provide custom solutions as per the customer requirements."
  //     }
  // ];

  const works = [
    {
      "location": "Trivandrum",
      "address": <div>F1, FG -Technomall, Technopark Campus, <br/>Trivandrum - 695 581</div>,
      "email": "tvm@supreme.net.in",
      "phones": ["0471 4067272", "98954 93132"]
    },
    { 
      "location": "Kottayam",
      "address": <div>LKR Buildings, Post Office Road, <br/>Kottayam - 686 001</div>,
      "email": "ktm@supreme.net.in",
      "phones": ["0481 2566561", "98950 76355", "98950 49497"]
      
    },
    {
      "location": "Kochi",
      "address": <div>4920C, Mayfair Buildings, Thevara, <br/>Cochin - 682 015</div>,
      "email": "cochin@supreme.net.in",
      "phones": ["97463 75330", "96451 81850"]
    }
  ]

  const[loadMore, setLoadMore] = useState(false);
  const[loadMoreText, setLoadMoreText] = useState("Load More");
  const loadMoreThreshold = 2;
  const toggleLoadMore = () => {
    setLoadMore(!loadMore);
    setLoadMoreText(loadMore ? "Load More" : "Load Less");
  }

  return (
    <footer id="contact" >
    <div className="contact-us-header">
          <h1>Contact Us</h1>
    </div>

    <section  className="container container__contact_us" >

      {/* <div className="portfolio__header" >
        <h1>Our Locations</h1>
      </div> */}
      {works.map((work, index) => {
        // if (index == 1) {
          //   return (
        //     <div key={index}>
        //       {/* <div className="portfolio_button_container">
        //         <button className="btn btn-primary" onClick={toggleLoadMore}>{loadMoreText}</button>
      //       </div> */}
      //         <br />
      //       <Work img={work.img} title={work.title} text={work.text}  />
      //     </div>
      //   );
      // }
      // if (!loadMore && index >= loadMoreThreshold) {
        //   return null;
        // }
        return <div key={index}>
                <ContactUsCard location={work.location} address={work.address} email={work.email} phones={work.phones} />
              </div>
      })}
    </section>
   </footer>
  );
}
/*
class ContactInfoFooter extends Component {
  constructor() {
    super();
    this.works = [
      {
        img: "https://miro.medium.com/max/1400/0*MYd28f8vpLiE34ij.jpeg",
        title: "Modern UI/UX Dashboard Design",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      },
      {
        img:
          "https://i.pinimg.com/originals/80/a1/17/80a1173fb50fe01bac507532338eb336.png",
        title: "Food and Consumation Web App",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      },
      {
        img:
          "https://i.pinimg.com/originals/56/ae/76/56ae76f7bc1b3e0edc962cea1af7035f.png",
        title: "Future Social Media Web 3.0 d'App",
        text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam asperiores"
      }
    ];
  }
  render() {
    return (
      <section className="container container__contact_us" id="portfolio">
        // {<div className="portfolio__header">
        //   <h1>See Our Works Or ContactInfoFooter</h1>
        //   <button className="btn btn-primary">More About</button>
        // </div> }
        {this.works.map((work, index) => {
          if (index == 1) {
            return (
              <>
                <div className="portfolio__header">
                  <h1>See Our Works Or ContactInfoFooter</h1>
                  <button className="btn btn-primary">More About</button>
                </div>
                <Work img={work.img} title={work.title} text={work.text} />
              </>
            );
          }
          return <Work img={work.img} title={work.title} text={work.text} />;
        })}
      </section>
    );
  }
}
*/
export default ContactInfoFooter;
