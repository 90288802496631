import { Component } from "react";
import "./style.css";
import scrollToSection from "../../lib/ScrollToSection";
import { Button } from "react-scroll";

const Banner = () => {
  const handleGetInTouch = (e) => {
    // scroll to the contact section
    e.preventDefault();
    scrollToSection("contact");
  };
  return (
    <section id="banner" className="banner__container">
      <div className="container">
        <h1>
          Let's Boost Your Business
          <br /> With The Latest Cutting Edge Technology
        </h1>
        <Button
          className="btn btn-primary"
          to="contact"
          spy={true}
          smooth={true}
          offset={-200}
          duration={100}
        >
          Get In Touch
        </Button>
      </div>
    </section>
  );
};
/*
class Banner extends Component {
  render() {
    return (
      <section id="banner" className="banner__container">
        <div className="container">
          <h1>
            Let's Boost Your Business
            <br /> With The Latest Technology
          </h1>
          <button className="btn btn-primary">Get In Touch</button>
        </div>
      </section>
    );
  }
}
*/
export default Banner;
