import { Component, useState } from "react";
import { BiBrain, BiCheckDouble } from "react-icons/bi";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination"; 
import { BsFilePerson } from "react-icons/bs";
import { AiFillCustomerService } from "react-icons/ai";
import { RiCustomerServiceFill } from "react-icons/ri";
import { Element } from "react-scroll";

const Intro = ()=> {

  const [more, setMore] = useState(false);
  const handleMore = (e) => {
    e.preventDefault()
    setMore(!more);
  };

  const moreText = "Over the years, we have built some strong and valuable business relationships with such high profile customers, US Global Technology Services (UST ) , Technopark and Infopark, Malayala Manorama,  IBS Technopark and Infopark, Speridian Technologies - Technopark, Guidehouse India Pvt. Ltd.,Technopark, Software Technology Parks of India, MMTV Cochin, CUSAT Cochin, Midas Group, AVG Group, IIITMK, Nissan Digital,  MG University(Various Depts/Schools)Caritas Hospital - Kottayam, St. Gregorios Medical Mission Hospital - Parumala, LifeLine Hospital - Adoor,MarSleeva Medicity, Pala, Muthoot Hospital - Kozhencherry, Pathanamthitta, Immaculate Heart of Mary Hospital, Kottayam, M.U.M. Hospital, Pallikoodam – Kalathipady, Lourdes Public School – Kottayam, Chinmaya Vidyalaya – Kottayam , Sanskara School - Infopark Kochi, Adi Sankara Institute of Engineering & Technology – Kalady, Muthoot Institute of Technology & Sciences, Ernakulam, Amal Jyothi College of Engineering - Kanjirappally, Saint Gits College of Engineering - Kottayam, Mar Augustinose College - Ramapuram, Chinmaya Vishwavidyapeeth - Piravom, Chinmaya International Foundation - Piravom , St.Joseph's College of Engineering & Technology -Pala, CMS College, Kottayam, St Thomas College of Engineering, Trivandrum. Mar Gregorios Educational Society - Trivandrum, Sree Sankara College – Kalady, Chinmaya Vidyalaya – Vaduthala, Chinmaya Vidyalaya – Tripunithara, Marian Engineering College, Trivandrum, etc, just to name a few"
  return (
    <div className="services__intro">
      <div className="pos-rel">
        <div className="services__intro__image">
          <img
            src="/assets/supreme/chip.jpg"
            alt=""
          />
        </div>
        <Swiper
          className="services__intro__miniSlider"
          spaceBetween={30}
          pagination={{
            clickable: true
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <div className="icon">
              <RiCustomerServiceFill />
            </div>
            <p> We have the optimum solutions for every one of your IT problems</p>
          </SwiperSlide>
          <SwiperSlide>
            <div className="icon">
              <BiCheckDouble />
            </div>
            <p> Quailty Products and Quality Service </p>
          </SwiperSlide>
        </Swiper>
      </div>
      <div>
      <Element name="customers">
        <div className="services__intro__text">
          <h1>Our Top Customers</h1>
          <p className="text">
            {more ? moreText : moreText.slice(0, 300) + "..."}
         </p>
          <button className="btn btn-primary" onClick={handleMore}> {more?"Less About":"More About"}</button>
        </div>
      </Element>
      </div>
    </div>
  );
}
/*
class Intro extends Component {
  render() {
    return (
      <div className="services__intro">
        <div className="pos-rel">
          <div className="services__intro__image">
            <img
              src="https://images.unsplash.com/photo-1567515004624-219c11d31f2e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
              alt=""
            />
          </div>
          <Swiper
            className="services__intro__miniSlider"
            spaceBetween={30}
            pagination={{
              clickable: true
            }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <div className="icon">
                <BiBrain />
              </div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon">
                <BiBrain />
              </div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
            </SwiperSlide>
          </Swiper>
        </div>
        <div>
          <div className="services__intro__text">
            <h1>We are the solutions for IT problems</h1>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Est ipsam
              asperiores voluptatibus sequi officiis minus? Eligendi explicabo
              sequi esse a unde ducimus omnis iure odit dolores, labore tenetur
              totam inventore eveniet dolorem.
            </p>
            <button className="btn btn-primary">More About</button>
          </div>
        </div>
      </div>
    );
  }
}
*/
export default Intro;
