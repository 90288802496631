import { Component, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";
import scrollToSection from "../../lib/ScrollToSection";
import { Element, Button, Link } from "react-scroll";

const About = () => {
  const [percentage, setPercentage] = useState(100);

  const handleGetInTouch = (e) => {
    // scroll to the contact section
    e.preventDefault();
    scrollToSection("quote");
  };

  return (
    <Element name="about" className="about__container">
      <div className="container">
        <div>
          {/* <img className='logo_text' src= '/assets/logo.jpeg' alt="logo" /> */}
          <h1>SUPREME COMPUTER SYSTEMS</h1>
          {/* Add since 1991 */}
          <h2 className="since-1991">Since 1991</h2>
          <p className="text">
            We introduce ourselves as Supreme Computer Systems, one of the
            premier organizations in Kerala, a System Integrator, Value added
            Reseller and Solution Provider engaged in the Business of IT
            Infrastructure Solutions as well as Facility Management. Started in
            1991, it is a very professionally managed set up, which has grown
            primarily because of the focus given on customer relations. We
            follow a very systematic and disciplined approach to Customer
            Relationship Management, which has helped us provide top quality and
            timely support to our customers, which has in turn helped us to
            enhance the customer satisfaction levels and thereby getting repeat
            orders and new customer referrals. We have a strong and dedicated
            manpower who are well trained and experienced in handling excellent
            pre and post sale support to customers. We also keep them updated
            with the latest developments in technology through regular training
            programs organized by our principal companies like Intel, HP,
            Microsoft, Dell, etc.
          </p>
          <div className="about__buttons">
            <Button
              className="btn btn-primary"
              to="quote"
              spy={true}
              smooth={true}
              offset={-200}
              duration={100}
            >
              Get In Touch
            </Button>
            <Link
            spy={true}
            smooth={true}
            offset={-200}
            duration={100} 
            to="services">
              <span>Our Services</span>
              <AiOutlineArrowDown />
            </Link>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className="about__image">
            <img src="/assets/supreme/server.jpg" alt="about" />
          </div>
          <div className="about__progress">
            <div className="progress_bar">
              <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </div>
            <h4>
              Striving to achieve
              <br />
              <small>
                highest levels of customer <br /> satisfaction
              </small>
            </h4>
          </div>
        </div>
      </div>
    </Element>
  );
};
/*
class About extends Component {
  constructor() {
    super();
    this.state = {
      percentage: 75
    };
  }
  render() {
    return (
      <section className="about__container">
        <div className="container">
          <div>
            <h1>Advance Innovation For IT Solutions</h1>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos
              eligendi molestiae ex harum, sed animi at aliquam quaerat odit
              repudiandae dicta veniam, beatae aliquid, voluptatum nostrum saepe
              doloribus excepturi. Facere!
            </p>
            <div className="about__buttons">
              <button className="btn btn-primary">Get In Touch</button>
              <a href="#services">
                <span>Our Services</span>
                <AiOutlineArrowDown />
              </a>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="about__image">
              <img
                src="https://st4.depositphotos.com/17586788/i/600/depositphotos_247586928-stock-photo-a-successful-business-girl-in.jpg"
                alt="about"
              />
            </div>
            <div className="about__progress">
              <div className="progress_bar">
                <CircularProgressbar
                  value={this.state.percentage}
                  text={`${this.state.percentage}%`}
                />
              </div>
              <h4>
                Business Growth
                <br />
                <small>Level is high</small>
              </h4>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
*/
export default About;
