import { Component } from "react";
import { AiFillMail } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";

const Contact = () => {
  return (
    <div className="contact">
      <a href="mailto:info@supreme.net.in">
        <AiFillMail />
        <span>info@supreme.net.in</span>
      </a>

      <a href="tel:+919645181850">
        <AiFillPhone />
        <span>+919645181850</span>
      </a>
    </div>
  );
}
/*
class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <a href="mailto:info@supreme.net.in">
          <AiFillMail />
          <span>info@supreme.net.in</span>
        </a>

        <a href="tel:+919645181850">
          <AiFillPhone />
          <span>+919645181850</span>
        </a>
      </div>
    );
  }
}
*/

export default Contact;
