import { Component } from "react";
import "./style_contact_us_footer.css";

const ContactUsCard = (props) => {
  return (
    <div className="contact_us_footer">
      {/* set width and height  */}
      <h2 className="contact-us-heading">{props.location}</h2>
      <div className="contact-item">
        <strong>Place:</strong> {props.location}
      </div>
      <div className="contact-item">
        <strong>Address:</strong> {props.address}
      </div>
      <div className="contact-item">
        <strong>Phone Numbers:</strong>
        <ul>
          {props.phones.map((phone, index) => (
            <li key={index}>{phone}</li>
          ))}
        </ul>
      </div>
      <div className="email">
        <strong>Email:</strong> {props.email}
      </div>
      {/* <p className="text">{props.text}</p> */}
    </div>
  );
}

/*
class contact_us_footer extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div className="portfolio__contact_us_footer">
        <img src={this.props.img} alt="" />
        <h2>{this.props.title}</h2>
        <p className="text">{this.props.text}</p>
      </div>
    );
  }
}
*/
export default ContactUsCard;
